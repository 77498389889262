<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder" @click="index()">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
			</div>
		</div>
		<div class="supplier-content">
			<div class="braft-output-content">
				<p>资源投放与患者教育、市场合作联系人：张女士<br><br>邮箱:<span style="font-size: 16px;">2313498079@qq.com</span><br> </p>
				<p>药品销售合作联系人：</p>
				<p>郑先生：<span style="font-size:16px">17774941210</span></p>
				<p>任女士：<span style="font-size:16px">15023258528</span></p>
				<p>全国服务热线<br>
				<span style="font-size: 16px;">4006316518</span>
				<p>
				<div class="item-list" @click="service(2)">相关文件:药品收货标准</div>
				</p>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			};
		},
		created() {

		},
		methods: {
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			service(type) {
				if (type == 0) { //隐私政策
					var routeData = this.$router.resolve({
						path: '/service'
					})
				} else if (type == 1) { //用户协议
					var routeData = this.$router.resolve({
						path: '/userAgreement'
					})
				} else if (type == 2) { //药品收货标准
					var routeData = this.$router.resolve({
						path: '/receivingStandards'
					})
				}
				window.open(routeData.href, "_blank");
			},
		}
	};
</script>

<style lang='scss'>
	.supplier-content {
		width: 80%;
		margin-left: 10%;

		.item-list {
			line-height: 30px;
			font-size: 14px;
			cursor: pointer;
		}

		.item-list:hover {
			color: #20557b;
			text-decoration: underline;
		}
	}
</style>